<template>
  <div>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card v-else>
          <div>
            <!-- Media -->
            <b-media class="mb-2">
              <template #aside>
                <b-avatar
                  ref="previewEl"
                  :src="platform.img_logo_after_auth"
                  :text="platform.name"
                  :variant="`light-primary`"
                  size="90px"
                  rounded
                />
              </template>
              <h4 class="mb-1">
                {{ platform.name }}
              </h4>
            </b-media>

            <!-- Platform Info: Input Fields -->

            <validation-observer ref="platformValidation">
              <b-form @submit.prevent="validationForm">
                <b-row>
                  <!-- Field: Name -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Nom"
                      label-for="name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="name"
                        rules="required|max:100|min:3"
                      >
                        <b-form-input
                          id="name"
                          v-model="platform.name"
                          placeholder="ccup"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['name']"
                          class="text-danger"
                        >{{ server_errors["name"][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Subdomain -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Sous-domaine"
                      label-for="subdomain"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="subdomain"
                        rules="required|max:100|min:3"
                      >
                        <b-input-group append=".ccup.io">
                          <b-form-input
                            id="subdomain"
                            v-model="platform.subdomain"
                            placeholder="ccup"
                            :state="errors.length > 0 ? false : null"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['subdomain']"
                          class="text-danger"
                        >{{ server_errors["subdomain"][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Slot -->
                  <b-col
                    cols="10"
                    md="4"
                  >
                    <b-form-group
                      label="Slot"
                      label-for="slot"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="slot"
                        rules="required|max_value:999999|min_value:1"
                      >
                        <b-form-input
                          id="slot"
                          v-model="platform.slot"
                          type="number"
                          placeholder="100"
                          :state="errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="server_errors && server_errors['slot']"
                          class="text-danger"
                        >{{ server_errors["slot"][0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: messaging -->
                  <b-col
                    cols="2"
                    md="2"
                  >
                    <b-form-group
                      label="Messagerie"
                      label-for="messaging"
                    >
                      <b-form-checkbox
                        id="messaging"
                        v-model="platform.is_messaging_enable"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label">{{ platform.is_messaging_enable ? 'oui' : 'non' }}</span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>

                  <!-- Field: Competition -->
                  <b-col
                    cols="12"
                    md="6"
                  >
                    <b-form-group
                      label="Competitions"
                      label-for="Competitions"
                    >
                      <v-select
                        v-model="competition"
                        :dir="'ltr'"
                        :options="competitionsOptions"
                        multiple
                        :clearable="true"
                        input-id="Competitions"
                      />
                      <small
                        v-if="server_errors && server_errors['competition_id']"
                        class="text-danger"
                      >{{ server_errors["competition_id"][0] }}</small>
                    </b-form-group>
                  </b-col>
                  <!-- Field: languges -->
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Français"
                      label-for="lang_fr"
                    >
                      <b-form-checkbox
                        id="lang_fr"
                        v-model="languages.lang_fr"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-fr.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Anglais"
                      label-for="lang_en"
                    >
                      <b-form-checkbox
                        id="lang_en"
                        v-model="languages.lang_en"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-en.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Allemand"
                      label-for="lang_de"
                    >
                      <b-form-checkbox
                        id="lang_de"
                        v-model="languages.lang_de"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-de.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Italien"
                      label-for="lang_it"
                    >
                      <b-form-checkbox
                        id="lang_it"
                        v-model="languages.lang_it"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-it.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                  <b-col
                    xl="2"
                  >
                    <b-form-group
                      label="Espagnol"
                      label-for="lang_es"
                    >
                      <b-form-checkbox
                        id="lang_es"
                        v-model="languages.lang_es"
                        :value="true"
                        :unchecked-value="false"
                      >
                        <span class="vs-label"><img
                          src="@/assets/images/flags/flag-es.png"
                          alt=""
                        ></span>
                      </b-form-checkbox>
                    </b-form-group>
                  </b-col>
                </b-row>
                <!-- Action Buttons -->
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="formLoading || !isPlatformModified"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  <b-spinner
                    v-show="formLoading"
                    small
                  />
                  Enregistrer
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col lg="12">
        <b-card
          v-if="loading"
          class="text-center"
        >
          <b-spinner label="Loading..." />
        </b-card>
        <b-card
          v-else
          title="Kits communication"
        >
          <div>
            <validation-observer ref="kitValidation">
              <b-form @submit.prevent="submitFormKit">
                <b-row
                  v-for="(communicationKit, index) in communicationKits"
                  :key="communicationKit.uuid"
                >
                  <b-col cols="12">
                    <template v-if="isPdfLink(communicationKit.file)">
                      <b-form-group :label="`lien pour : ${communicationKit.title.fr}`">
                        <div class="d-flex align-items-center">
                          <a
                            class="underline"
                            target="_blank"
                            :href="communicationKit.file"
                          ><u>{{ communicationKit.file }}</u></a>
                          <b-button
                            variant="warning"
                            class="ml-1 btn-icon"
                            @click="removePdf(index)"
                          >
                            <feather-icon icon="RefreshCwIcon" />
                          </b-button>
                        </div>
                      </b-form-group>
                    </template>
                    <template v-else>
                      <b-form-group
                        :label="`fichier pour : ${communicationKit.title.fr}`"
                        label-for="file"
                      >
                        <b-form-file
                          v-model="communicationKit.file"
                          accept="file/pdf"
                          placeholder="Choisissez un fichier ou déposez-le ici..."
                          drop-placeholder="Déposer le fichier ici..."
                        />
                      </b-form-group>
                    </template>
                  </b-col>
                </b-row>
                <b-button
                  variant="primary"
                  type="submit"
                  :disabled="formLoading || !isCommunicationKitModified"
                  class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                >
                  <b-spinner
                    v-show="formLoading"
                    small
                  />
                  Enregistrer {{ isCommunicationKitModified }}
                </b-button>
              </b-form>
            </validation-observer>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BCard,
  BSpinner,
  BInputGroup,
  BFormCheckbox,
  BFormFile,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import axiosIns from '@/libs/axios'
import {
  required, max, min, max_value, min_value,
} from '@validations'

export default {
  name: 'PlatformsEdit',
  components: {
    ValidationProvider,
    ValidationObserver,
    BButton,
    BMedia,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BCard,
    vSelect,
    BSpinner,
    BInputGroup,
    BFormCheckbox,
    BFormFile,
  },
  data() {
    return {
      loading: true,
      formLoading: false,
      competitionsOptions: [],
      competition: [],
      required,
      max,
      min,
      max_value,
      min_value,
      platform: {},
      communicationKits: [],
      languages: {
        lang_fr: false,
        lang_en: false,
        lang_de: false,
        lang_it: false,
        lang_es: false,
      },
      languagesReference: null,
      platformReference: null,
      communicationKitsReference: null,
      server_errors: null,
    }
  },
  computed: {
    isPlatformModified() {
      return (
        JSON.stringify(this.platform) !== this.platformReference
        || (this.platform.competition ? this.platform.competition.uuid : null)
          !== (this.competition ? this.competition.value : null) || this.isLanguagesModified
      )
    },
    isLanguagesModified() {
      return JSON.stringify(this.languages) !== this.languagesReference
    },
    isCommunicationKitModified() {
      return JSON.stringify(this.communicationKits) !== this.communicationKitsReference
    },
  },
  mounted() {
    this.fetchPlatform()
  },
  beforeDestroy() {
    this.$store.commit('app/UPDATE_PAGE_META', {
      pageTitle: '',
      breadcrumb: [],
    })
  },
  methods: {
    async fetchPlatform() {
      try {
        this.loading = true
        const platform = await axiosIns.get(
          `platforms/${this.$route.params.id}`,
        )
        const communicationKits = await axiosIns.get(
          `platforms/${this.$route.params.id}/communicationKits`,
        )
        this.platformReference = JSON.stringify(platform.data.data)
        this.communicationKitsReference = JSON.stringify(communicationKits.data.data)
        this.platform = platform.data.data
        this.communicationKits = communicationKits.data.data
        if (this.platform.languages) {
          this.platform.languages.forEach(language => {
            if (language.language_code === 'fr') {
              this.languages.lang_fr = true
            }
            if (language.language_code === 'en') {
              this.languages.lang_en = true
            }
            if (language.language_code === 'de') {
              this.languages.lang_de = true
            }
            if (language.language_code === 'it') {
              this.languages.lang_it = true
            }
            if (language.language_code === 'es') {
              this.languages.lang_es = true
            }
          })
        }
        this.languagesReference = JSON.stringify(this.languages)
        const competitions = await axiosIns.get('/competitions')
        competitions.data.data.forEach(el => {
          this.competitionsOptions.push({
            label: el.name,
            value: el.uuid,
          })
        })
        this.platform.competitions.forEach(competition => {
          this.competition.push({
            label: competition.name,
            value: competition.uuid,
          })
        })
        this.$store.commit('app/UPDATE_PAGE_META', {
          pageTitle: this.platform.name,
          breadcrumb: [
            {
              text: 'Plateformes',
              active: false,
              to: {
                name: 'platforms',
              },
            },
            {
              text: this.platform.name,
              active: false,
              to: {
                name: 'platforms-view',
                params: { id: this.platform.uuid },
              },
            },
            {
              text: 'éditer',
              active: true,
            },
          ],
        })
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitFormKit() {
      try {
        this.formLoading = true
        this.server_errors = null
        const form = new FormData()
        this.communicationKits.forEach(el => {
          if (el.file) {
            form.append(`communication_kit_files_${el.uuid}`, el.file)
          }
        })
        const communicationKits = await axiosIns.post(`/platforms/${this.$route.params.id}/communicationKits`, form)
        console.log(communicationKits)
        this.communicationKitsReference = JSON.stringify(communicationKits.data.data)
        this.communicationKits = communicationKits.data.data
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme à bien été créée',
            },
          },
          { timeout: 5000 },
        )
        this.formLoading = false
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    async submitForm() {
      try {
        this.formLoading = true
        this.server_errors = null
        const form = new FormData()
        form.append('name', this.platform.name)
        form.append('subdomain', this.platform.subdomain ? this.platform.subdomain.toLowerCase() : '')
        form.append('slot', this.platform.slot)
        form.append('is_messaging_enable', this.platform.is_messaging_enable)
        this.competition.forEach(competition => {
          form.append('competition_id[]', competition.value)
        })
        if (this.languages.lang_fr) {
          form.append('languages_code[]', 'fr')
        }
        if (this.languages.lang_en) {
          form.append('languages_code[]', 'en')
        }
        if (this.languages.lang_de) {
          form.append('languages_code[]', 'de')
        }
        if (this.languages.lang_it) {
          form.append('languages_code[]', 'it')
        }
        if (this.languages.lang_es) {
          form.append('languages_code[]', 'es')
        }
        const platform = await axiosIns.post(
          `platforms/${this.$route.params.id}`,
          form,
        )
        this.platformReference = JSON.stringify(platform.data.data)
        this.platform = platform.data.data
        if (this.platform.competition) {
          this.competition = {
            label: this.platform.competition.name,
            value: this.platform.competition.uuid,
          }
        }
        this.formLoading = false
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Enregistré !',
              icon: 'CheckCircleIcon',
              variant: 'success',
              text: 'La plateforme à bien été mise a jour',
            },
          },
          { timeout: 5000 },
        )
      } catch (err) {
        this.formLoading = false
        this.server_errors = err.response.data.errors
        this.$toast(
          {
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Erreur',
              icon: 'AlertCircleIcon',
              variant: 'danger',
              text: `Une erreur s'est produite. erreur: ${err}`,
            },
          },
          { timeout: 5000 },
        )
      }
    },
    validationForm() {
      this.$refs.platformValidation.validate().then(success => {
        if (success) {
          this.submitForm()
        }
      })
    },
    isPdfLink(pdf) {
      const regex = /(https?:\/\/.*\.(?:pdf))/
      if (pdf && typeof pdf === 'string' && pdf.match(regex)) return true
      return false
    },
    removePdf(communicationKitIndex) {
      this.communicationKits[communicationKitIndex].file = ''
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
